'use client';

import * as Sentry from '@sentry/nextjs';
import fetchWithAuth from './fetch-with-token-refresh.client';
import fetcher from './fetcher';
import { GraphqlFetchConfig } from './types';

const requestInfo =
  process.env.GRAPHQL_URL ??
  process.env.NEXT_PUBLIC_GRAPHQL_URL ??
  'http://localhost:4001/graphql';

export async function graphqlFetch<TVariables>(
  config: GraphqlFetchConfig<TVariables>,
) {
  try {
    const { query, variables, withAuth = false } = config;
    const requestInit = {
      method: 'POST',
      body: JSON.stringify({
        query,
        variables: variables || {},
      }),
    };
    if (!withAuth) {
      return fetcher(undefined, requestInfo, requestInit);
    }
    return fetchWithAuth(requestInfo, requestInit);
  } catch (error) {
    console.log('error when fetching', error);
    Sentry.captureException(error);
  }
}

export default graphqlFetch;
